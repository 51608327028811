import Vue from "vue";
import Header from "@/views/Admin/Commons/Header.vue";
import CreateTabs from "@/views/Admin/Commons/Tabs.vue";
import Alertize from "@/components/Alertize.vue";
import { mapActions, mapGetters } from "vuex";
import IFrame from "@/views/Admin/AudienceBlender/Report/Iframe.vue";
import { getPermisionMenu } from "@/utils/permissionResolve";

export default Vue.extend({
  name: "audienceMarketplace",
  props: {},
  components: { Header, CreateTabs, Alertize, IFrame },
  data: () => ({
    title: "audienceMarketplaceLogic",
    currentTab: 0,
    expanded: false,
    src: "https://audienceblender.adsmovil.com",
  }),
  async created() {},
  async mounted() {
    this.$nextTick(async () => {});
  },
  computed: {
    ...mapGetters("loading", ["isLoading"]),
    ...mapGetters("profile", ["getPermission", "getAbility"]),
    getPermissionData() {
      return getPermisionMenu();
    },
    canAccess() {
      return this.getAbility.can(this.getPermissionData.actions.audienceMarketplace, this.getPermissionData.subject);
    },
  },
  methods: {
    ...mapActions("loading", ["setLoadingData"]),
  },
  watch: {
    getPermission: {
      async handler(val, oldVal) {
        if (!this.canAccess) return;
        await this.setLoadingData();
      },
      deep: true,
      immediate: true,
    },
  },
});
